import React from 'react';
import style from './styles.module.css';

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'primary' | 'error' | 'text' | 'icon' | 'pagination';
  backgroundColor?: string;
  size?: 'small' | 'medium' | 'large' | 'none';
  label?: string;
  icon?: React.ReactNode;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
}

export const Button = ({
  variant = 'default',
  size = 'medium',
  backgroundColor,
  label,
  icon,
  iconStart,
  iconEnd,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={`${style.button} ${style[size]} ${style[variant]}`}
      style={{ backgroundColor }}
      {...props}
    >
      {['icon', 'pagination'].includes(variant) ? (
        <>{icon && <span className={`${style.buttonIcon}`}>{icon}</span>}</>
      ) : (
        <>
          {iconStart && <span className={style.buttonIcon}>{iconStart}</span>}
          {label}
          {iconEnd && <span className={style.buttonIcon}>{iconEnd}</span>}
        </>
      )}
    </button>
  );
};

import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '../styles.module.css';

export interface TabsContentProps
  extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  active?: boolean;
}

export const TabsContent: React.FC<TabsContentProps> = ({
  children,
  active = false,
  ...props
}) => {
  return (
    <section
      className={`${styles.tabsContent} ${active ? styles.active : ''}`}
      role="tabpanel"
      {...props}
    >
      {children}
    </section>
  );
};

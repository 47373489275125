import React from 'react';
import styles from './styles.module.css';
import { Title } from '../Title';
import { Option, SelectInput } from '../SelectInput';
import { IoMdPin } from 'react-icons/io';
import { FaRegCalendar } from 'react-icons/fa';
import { FaPhoneAlt } from 'react-icons/fa';

export interface DepartmentSections {
  id: string;
  name: string;
  address?: string;
  postalCode?: string;
  district?: string;
  city?: string;
  state?: string;
  countryCode?: string;
  openingTime?: string;
  mapUrl?: string;
  active?: boolean;
  complement?: string;
  contact?: string;
}

export interface ServiceLocationProps {
  title: string;
  labelSelect?: string;
  departmentSections: DepartmentSections[];
  department: Option | null;
  setDepartment: (option: Option | null) => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  mapUrl: string;
}

export const ServiceLocation = ({
  title,
  departmentSections,
  labelSelect = 'Selecione a unidade de atendimento',
  isOpen,
  setIsOpen,
  department,
  setDepartment,
  mapUrl,
}: ServiceLocationProps) => {
  const selectedDepartment = departmentSections.find(
    section => section.id === department?.value
  );

  const options: Option[] = departmentSections.map(section => ({
    label: section.name,
    value: section.id,
  }));

  return (
    <div className={styles.cardBaseLocation}>
      <div className={styles.baseLocation}>
        <Title title={title} />
        <SelectInput
          options={options}
          label={labelSelect}
          value={department}
          setValue={setDepartment}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
        />
      </div>
      {selectedDepartment && (
        <>
          <div className={styles.divideLocation} />
          {mapUrl && (
            <div className={styles.locationMap}>
              <iframe
                src={mapUrl}
                title={`Mapa da localização da unidade ${selectedDepartment.name}`}
                aria-label={`Mapa da localização da unidade ${selectedDepartment.name}`}
                width="100%"
                height="220px"
                className={styles.map}
              />
            </div>
          )}
          <div className={styles.baseAddress}>
            <div className={styles.department}>{selectedDepartment.name}</div>
            <div className={styles.wrapAddress}>
              <span role="img" aria-label="Ícone de localização">
                <IoMdPin className={styles.icon} fontSize={16} />
              </span>
              <div>
                <div className={styles.subTitle}>Endereço</div>
                <div className={styles.typography}>
                  {selectedDepartment.address},
                  {selectedDepartment.complement
                    ? ` ${selectedDepartment.complement},`
                    : ''}{' '}
                  {selectedDepartment.district}, {selectedDepartment.city} /{' '}
                  {selectedDepartment.state},{' '}
                  {`CEP: ${selectedDepartment.postalCode}`}
                </div>
              </div>
            </div>
            {selectedDepartment.openingTime && (
              <div className={styles.wrapAddress}>
                <span role="img" aria-label="Ícone de calendário">
                  <FaRegCalendar className={styles.icon} fontSize={16} />
                </span>
                <div>
                  <div className={styles.subTitle}>Horário de atendimento</div>
                  <div className={styles.typography}>
                    {selectedDepartment.openingTime}
                  </div>
                </div>
              </div>
            )}
            {selectedDepartment.contact && (
              <div className={styles.wrapAddress}>
                <span role="img" aria-label="Ícone de telefone">
                  <FaPhoneAlt className={styles.icon} fontSize={16} />
                </span>
                <div>
                  <div className={styles.subTitle}>Telefone para contato</div>
                  <div className={styles.typography}>
                    {selectedDepartment.contact}
                  </div>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

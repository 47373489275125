import React, {
  AnchorHTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  createRef,
} from 'react';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

import styles from '../styles.module.css';

export interface AccordionTriggerProps
  extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  active?: boolean;
  onToggle: (id: string) => void;
  icon?: React.ReactNode;
}

export const AccordionTrigger: React.FC<AccordionTriggerProps> = ({
  children,
  active = false,
  onToggle,
  icon,
  href,
  ...props
}) => {
  const anchorRef = createRef<HTMLAnchorElement>();

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    onToggle(anchorRef.current!.parentElement!.id);
  }

  return (
    <a
      href={href}
      className={styles.accordionTrigger}
      onClick={handleClick}
      ref={anchorRef}
      {...props}
    >
      <header className={styles.accordionHeader}>{children}</header>

      {icon ? (
        <>{icon}</>
      ) : (
        <MdOutlineKeyboardArrowDown
          className={`${styles.accordionArrowIcon} ${
            active ? styles.active : ''
          }`}
          size={40}
        />
      )}
    </a>
  );
};

import React, {
  AnchorHTMLAttributes,
  MouseEvent,
  PropsWithChildren,
  createRef,
} from 'react';

import styles from '../styles.module.css';

export interface TabsTriggerProps
  extends PropsWithChildren<AnchorHTMLAttributes<HTMLAnchorElement>> {
  active?: boolean;
  onToggle: (hash: string) => void;
}

export const TabsTrigger: React.FC<TabsTriggerProps> = ({
  children,
  active = false,
  onToggle,
  href,
  ...props
}) => {
  const anchorRef = createRef<HTMLAnchorElement>();

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    onToggle(anchorRef.current!.hash);
  }

  return (
    <a
      href={href}
      className={`${styles.tabsTrigger} ${active ? styles.active : ''}`}
      onClick={handleClick}
      role="tab"
      ref={anchorRef}
      {...props}
    >
      {children}
    </a>
  );
};

import React, { Component, createRef } from 'react';
import styles from './styles.module.css';
import { IoChevronDown } from 'react-icons/io5';

export interface Option {
  value: string;
  label: string;
}

export interface SelectInputProps {
  label: string;
  value: Option | null;
  setValue: (option: Option | null) => void;
  options: Option[];
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  icon?: React.ReactNode;
}

export class SelectInput extends Component<SelectInputProps> {
  private dropdownRef = createRef<HTMLDivElement>();
  private triggerRef = createRef<HTMLDivElement>();

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event: MouseEvent) => {
    if (
      this.dropdownRef.current &&
      !this.dropdownRef.current.contains(event.target as Node) &&
      this.triggerRef.current &&
      !this.triggerRef.current.contains(event.target as Node)
    ) {
      this.props.setIsOpen(false);
    }
  };

  handleOptionClick = (option: Option) => {
    this.props.setValue(option);
    this.props.setIsOpen(false);
    this.triggerRef.current?.focus();
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      this.props.setIsOpen(!this.props.isOpen);
    } else if (e.key === 'Escape') {
      this.props.setIsOpen(false);
    }
  };

  handleOptionKeyDown = (e: React.KeyboardEvent, option: Option) => {
    if (e.key === 'Enter' || e.key === ' ') {
      this.handleOptionClick(option);
    }
  };

  render() {
    const { label, value, options, isOpen, icon } = this.props;

    return (
      <div className={styles.formControl}>
        <label id={label} className={styles.label}>
          {label}
        </label>
        <div
          ref={this.triggerRef}
          role="combobox"
          aria-haspopup="listbox"
          aria-expanded={isOpen}
          aria-labelledby="select-label"
          aria-controls="select-dropdown"
          tabIndex={0}
          className={`${styles.wrapSelect} ${isOpen ? styles.openselect : ''}`}
          onClick={() => this.props.setIsOpen(!isOpen)}
          onKeyDown={this.handleKeyDown}
        >
          {icon && icon}
          <div className={styles.selectedValue}>
            {value?.label || 'Selecione...'}
          </div>
          <IoChevronDown className={styles.customIcon} fontSize={20} />
        </div>
        {isOpen && (
          <div
            ref={this.dropdownRef}
            id="select-dropdown"
            role="listbox"
            aria-labelledby="select-label"
            className={`${styles.dropdown} ${isOpen ? styles.open : ''}`}
          >
            {options.map(option => (
              <div
                key={option.value}
                role="option"
                aria-selected={value?.label === option.label}
                tabIndex={0}
                className={styles.option}
                onClick={() => this.handleOptionClick(option)}
                onKeyDown={e => this.handleOptionKeyDown(e, option)}
              >
                {option.label}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  }
}

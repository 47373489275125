import React, { ChangeEvent } from 'react';
import styles from './styles.module.css';
import { Title } from '../Title';
import { Switch } from '../Switch';
import { InputSearch } from '../InputSearch';
import { Dropdown, DropdownItem } from '../Dropdown';
import { DropdownTrigger } from '../Dropdown/DropdownTrigger';
import { DropdownTitle } from '../Dropdown/DropdownTitle';
import { DropdownContent } from '../Dropdown/DropdownContent';
import { IconType } from 'react-icons';
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  AccordionTrigger,
} from '../Accordion';

export type DropdownItems = {
  id: string;
  label: string;
  icon?: IconType;
  checked: boolean;
  setChecked: () => void;
};

export type DropdownData = {
  id: string;
  title: string;
  active: boolean;
  onToggle: () => void;
  dropdownItems: DropdownItems[];
};

export type SwitchData = {
  id: string;
  label: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
};

export type SideFilterProps = {
  title: string;
  icon?: React.ReactNode;
  insideAccordion?: boolean;
  handleToggle: (id: string) => void;
  active: boolean;
  searchPlaceholder: string;
  onSearchChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onSearchClick: () => void;
  switchData: SwitchData[];
  dropdownData: DropdownData[];
  searchValue?: string;
};

export const SideFilter: React.FC<SideFilterProps> = ({
  title,
  searchPlaceholder,
  onSearchChange,
  onSearchClick,
  switchData,
  dropdownData,
  icon,
  insideAccordion = false,
  handleToggle,
  searchValue,
  active,
}) => {
  const renderContent = () => (
    <div className={styles.sideFilter}>
      {!insideAccordion && (
        <div className={styles.sideFilterTitle}>
          <Title title={title} />
          {icon}
        </div>
      )}

      <section className={styles.searchContainer}>
        <label className={styles.label} htmlFor="search">
          Buscar por palavra-chave
        </label>

        <InputSearch
          id="search"
          style={{ width: '100%' }}
          placeholder={searchPlaceholder}
          onSubmit={onSearchClick}
          onChange={onSearchChange}
          {...(searchValue && { value: searchValue })}
        />
      </section>

      <hr className={styles.divider} />

      {switchData.length > 0 && (
        <>
          <section className={styles.switchContainer}>
            <span className={styles.label}>Forma de realização</span>

            {switchData.map(({ id, label, setChecked, checked }) => (
              <span key={id} className={styles.switchField}>
                <Switch id={id} checked={checked} setChecked={setChecked} />

                <label htmlFor={id}>{label}</label>
              </span>
            ))}
          </section>

          <hr className={styles.divider} />
        </>
      )}

      <section>
        {dropdownData.map(({ id, title, active, onToggle, dropdownItems }) => (
          <Dropdown key={id}>
            <DropdownTrigger onToggle={onToggle} active={active}>
              <DropdownTitle>{title}</DropdownTitle>
            </DropdownTrigger>

            <DropdownContent active={active}>
              {dropdownItems.map(({ id, label, icon, checked, setChecked }) => (
                <DropdownItem
                  key={id}
                  id={id}
                  Icon={icon}
                  setChecked={setChecked}
                  checked={checked}
                >
                  {label}
                </DropdownItem>
              ))}
            </DropdownContent>
          </Dropdown>
        ))}
      </section>
    </div>
  );

  return insideAccordion ? (
    <Accordion background="transparent">
      <AccordionTrigger onToggle={handleToggle} active={active}>
        <AccordionTitle marker>{title}</AccordionTitle>
      </AccordionTrigger>
      <AccordionContent active={active}>{renderContent()}</AccordionContent>
    </Accordion>
  ) : (
    renderContent()
  );
};

import React from 'react';
import styles from './styles.module.css';
import { Button } from '../Button';
import { FaArrowRight } from 'react-icons/fa6';

export interface ServiceCardProps {
  isDigital?: boolean;
  isOnline?: boolean;
  category?: string;
  title: string;
  onInfoClick?: () => void;
  onRequestClick?: () => void;
}

export const CardServices = ({
  isDigital = false,
  isOnline = false,
  category,
  title,
  onInfoClick,
  onRequestClick,
}: ServiceCardProps) => {
  return (
    <div className={styles.card}>
      <div className={styles.serviceChips}>
        {isDigital && (
          <span className={styles.serviceChip}>Serviço digital</span>
        )}
        {isOnline && <span className={styles.serviceChip}>Agendamento</span>}
      </div>
      <div>
        {category && (
          <div className={styles.wrapCategory}>
            <span className={styles.infoMarker} />
            <p className={styles.category}>{category}</p>
          </div>
        )}
        <h3 className={styles.title}>{title}</h3>
      </div>
      <div className={styles.buttonContainer}>
        <Button
          label="Mais informações"
          onClick={onInfoClick}
          size="none"
          variant="text"
          aria-label="Mais informações"
          className={styles.serviceCardLink}
        />
        {(isDigital || isOnline) && (
          <Button
            iconEnd={<FaArrowRight />}
            label="Solicitar"
            onClick={onRequestClick}
            aria-label="Solicitar serviço"
            className={styles.serviceCardButton}
          />
        )}
      </div>
    </div>
  );
};

import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '../styles.module.css';

export interface AccordionTitleProps
  extends PropsWithChildren<HTMLAttributes<HTMLElement>> {
  marker?: boolean;
}

export const AccordionTitle: React.FC<AccordionTitleProps> = ({
  children,
  marker = false,
  ...props
}) => {
  return (
    <h3
      className={`${styles.accordionTitle} ${
        marker ? styles.row : styles.column
      }`}
      {...props}
    >
      {marker && <div className={styles.marker} />}
      {children}
    </h3>
  );
};

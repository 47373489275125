import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from './styles.module.css';

export interface AccordionProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  background?: 'white' | 'transparent';
}

export const Accordion: React.FC<AccordionProps> = ({
  children,
  background = 'white',
  ...props
}) => {
  return (
    <article className={`${styles.accordion} ${styles[background]}`} {...props}>
      {children}
    </article>
  );
};

export * from './AccordionItem';
export * from './AccordionTrigger';
export * from './AccordionSubtitle';
export * from './AccordionTitle';
export * from './AccordionContent';

import React from 'react';
import styles from './styles.module.css';
import { MdHideImage } from 'react-icons/md';

export interface CardProps {
  info?: string;
  title: string;
  imageUrl?: string;
  onClick?: () => void;
  onKeyDown?: () => void;
}

export const CardMedia = ({
  info,
  title,
  imageUrl,
  onClick,
  onKeyDown,
}: CardProps) => {
  return (
    <div
      className={styles.card}
      onClick={onClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      {imageUrl ? (
        <img className={styles.image} src={imageUrl} alt="Imagem" />
      ) : (
        <div className={styles.boxHideImage}>
          <span className={styles.iconPlaceholder}>
            <MdHideImage />
          </span>
        </div>
      )}
      <div className={styles.content}>
        {info && (
          <p className={styles.info}>
            <span className={styles.infoMarker} />
            {info}
          </p>
        )}
        <h3 className={styles.title}>{title}</h3>
      </div>
    </div>
  );
};

import React from 'react';
import styles from './styles.module.css';
import { MdHideImage } from 'react-icons/md';

export interface CardServicesHighlightProps {
  info?: string;
  title: string;
  imageUrl?: string;
  onClick?: () => void;
}

export const CardServicesHighlight: React.FC<CardServicesHighlightProps> = ({
  info,
  title,
  imageUrl,
  onClick,
}) => {
  return (
    <div
      className={`${styles.card}`}
      onClick={onClick}
      role={onClick ? 'button' : undefined}
      tabIndex={onClick ? 0 : undefined}
      onKeyDown={e => {
        if (onClick && (e.key === 'Enter' || e.key === ' ')) {
          onClick();
        }
      }}
    >
      {imageUrl ? (
        <img
          className={`${styles.image} ${styles.roundedTop}`}
          src={imageUrl}
          alt={
            title ? `Imagem ilustrativa de ${title}` : 'Imagem não disponível'
          }
        />
      ) : (
        <div
          className={`${styles.boxHideImage} ${styles.roundedTop}`}
          aria-hidden="true"
        >
          <span className={styles.iconPlaceholder}>
            <MdHideImage />
          </span>
        </div>
      )}
      <div className={styles.cardContent}>
        {info && (
          <span className={styles.info}>
            <span className={styles.infoMarker} />
            {info}
          </span>
        )}
        <h3 className={styles.title}>{title}</h3>
      </div>
    </div>
  );
};

import React, { HTMLAttributes, MouseEvent } from 'react';
import styles from './styles.module.css';
import { Button } from '../Button';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';

export interface EnumPaginationProps extends HTMLAttributes<HTMLElement> {
  initialPage?: number;
  totalPages: number;
  onPageChange: (page: number) => void;
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

const FIRST_PAGE = 1;
const MAX_DISPLAYED_PAGES = 5;
const HALF_DISPLAYED_PAGES = Math.floor((MAX_DISPLAYED_PAGES - 1) / 2);

export const EnumPagination: React.FC<EnumPaginationProps> = ({
  initialPage = FIRST_PAGE,
  totalPages,
  onPageChange,
  currentPage = FIRST_PAGE,
  setCurrentPage,
  ...props
}) => {
  if (totalPages <= FIRST_PAGE) {
    throw new Error('Pagination component requires at least 2 pages to work');
  }

  if (initialPage && (initialPage < FIRST_PAGE || initialPage > totalPages)) {
    throw new Error(
      `Initial page must be between ${FIRST_PAGE} and the total number of pages (${totalPages})`
    );
  }

  function handlePreviousPageButtonClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  }

  function handleNextPageButtonClick(e: MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  }

  function handlePageButtonClick(
    e: MouseEvent<HTMLButtonElement>,
    page: number
  ) {
    e.preventDefault();
    setCurrentPage(page);
    onPageChange(page);
  }

  const generatePageNumbers = () => {
    const pages: (number | string)[] = [];

    let startPage = Math.max(
      FIRST_PAGE,
      Math.min(
        currentPage - HALF_DISPLAYED_PAGES,
        totalPages - MAX_DISPLAYED_PAGES + 1
      )
    );
    const endPage = Math.min(startPage + MAX_DISPLAYED_PAGES - 1, totalPages);

    for (let i = startPage; i <= endPage; i++) {
      if (
        (i === startPage && i > FIRST_PAGE) ||
        (i === endPage &&
          i < totalPages &&
          currentPage <= totalPages - MAX_DISPLAYED_PAGES)
      ) {
        pages.push('...');
      } else {
        pages.push(i);
      }
    }

    return pages;
  };

  const pages = generatePageNumbers();

  return (
    <nav
      style={{
        display: 'grid',
        gap: '16px',
        gridTemplateColumns: `repeat(${pages.length + 2}, 1fr)`,
        gridTemplateRows: '1fr',
      }}
      {...props}
    >
      <Button
        onClick={handlePreviousPageButtonClick}
        disabled={currentPage <= FIRST_PAGE}
        icon={<FaArrowLeft />}
        variant="pagination"
        size="small"
        className={styles.buttonArrow}
      />

      {pages.map((page, index) =>
        typeof page === 'number' ? (
          <button
            key={index}
            onClick={(e) => handlePageButtonClick(e, page)}
            className={
              page === currentPage ? styles.pageButtonActive : styles.pageButton
            }
          >
            {page}
          </button>
        ) : (
          <span key={index} className={styles.pageButton}>
            {page}
          </span>
        )
      )}

      <Button
        onClick={handleNextPageButtonClick}
        disabled={currentPage >= totalPages}
        icon={<FaArrowRight />}
        variant="pagination"
        size="none"
        className={styles.buttonArrow}
      />
    </nav>
  );
};

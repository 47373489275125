import React, { AnchorHTMLAttributes, MouseEvent, createRef } from 'react';

import styles from '../styles.module.css';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

export interface DropdownTriggerProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  active?: boolean;
  onToggle: () => void;
}

export const DropdownTrigger: React.FC<DropdownTriggerProps> = ({
  children,
  href,
  active = false,
  onToggle,
  ...props
}) => {
  const anchorRef = createRef<HTMLAnchorElement>();

  function handleClick(e: MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    onToggle();
  }

  return (
    <a
      href={href}
      className={styles.dropdownTrigger}
      onClick={handleClick}
      ref={anchorRef}
      {...props}
    >
      <header className={styles.dropdownHeader}>{children}</header>

      <MdOutlineKeyboardArrowDown
        className={`${styles.dropdownArrowIcon} ${active ? styles.active : ''}`}
        size={40}
      />
    </a>
  );
};

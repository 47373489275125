import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from './styles.module.css';

export interface TabsProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {}

export const Tabs: React.FC<TabsProps> = ({ children, ...props }) => {
  return (
    <section className={styles.tabs} {...props}>
      {children}
    </section>
  );
};

export * from './TabsNav';
export * from './TabsTrigger';
export * from './TabsContent';

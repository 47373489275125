import React, { HTMLAttributes } from 'react';
import { TfiMenuAlt } from 'react-icons/tfi';

import styles from '../styles.module.css';

export interface TabsHeaderProps extends HTMLAttributes<HTMLElement> {
  withMenuIcon?: boolean;
}

export const TabsNav: React.FC<TabsHeaderProps> = ({
  children,
  withMenuIcon = false,
  ...props
}) => {
  return (
    <nav className={styles.tabsNav} {...props}>
      <TfiMenuAlt
        size={24}
        style={{ display: withMenuIcon ? 'block' : 'none' }}
      />
      {children}
    </nav>
  );
};

import React, { HTMLAttributes } from 'react';

import styles from './styles.module.css';

export interface DropdownProps extends HTMLAttributes<HTMLDivElement> {}

export const Dropdown: React.FC<DropdownProps> = ({ children, ...props }) => {
  return (
    <article className={`${styles.dropdown}`} {...props}>
      {children}
    </article>
  );
};

export * from './DropdownItem';

import React, { ChangeEvent, InputHTMLAttributes } from 'react';
import { IoMdClose } from 'react-icons/io';

import styles from '../styles.module.css';
import { IconType } from 'react-icons';

export interface DropdownItemProps
  extends InputHTMLAttributes<HTMLInputElement> {
  Icon?: IconType;
  setChecked: (checked: boolean) => void;
}

export const DropdownItem: React.FC<DropdownItemProps> = ({
  children,
  id,
  checked,
  Icon,
  setChecked,
  onChange,
  ...props
}) => {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setChecked(e.target.checked);
    onChange?.(e);
  }

  return (
    <label htmlFor={id} className={styles.dropdownItem}>
      <div>
        {Icon && <Icon className={styles.dropdownItemIcon} size={20} />}
        {children}
      </div>

      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        {...props}
      />

      <IoMdClose
        className={`${styles.dropdownItemIcon} ${styles.dropdownItemCloseIcon}`}
        size={20}
      />
    </label>
  );
};

import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '../styles.module.css';

export interface DropdownTitleProps
  extends PropsWithChildren<HTMLAttributes<HTMLElement>> {}

export const DropdownTitle: React.FC<DropdownTitleProps> = ({
  children,
  ...props
}) => {
  return (
    <h3 className={styles.dropdownTitle} {...props}>
      {children}
    </h3>
  );
};

import React, { FormEvent, InputHTMLAttributes } from 'react';
import { MdOutlineSearch } from 'react-icons/md';

import styles from './styles.module.css';
import { Button } from '../Button';

export interface InputSearchProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'onSubmit'> {
  buttonLabel?: string;
  onSubmit: (search: string) => void;
}

export const InputSearch: React.FC<InputSearchProps> = ({
  id = 'search',
  type = 'text',
  buttonLabel = 'Buscar',
  onSubmit,
  ...props
}) => {
  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    const formData = new FormData(e.currentTarget);
    const search = formData.get('search')!.toString();

    onSubmit(search);
  }

  return (
    <form onSubmit={handleSubmit}>
      <label className={styles.inputSearchContainer} htmlFor={id}>
        <MdOutlineSearch className={styles.inputSearchIcon} size={24} />

        <input
          id={id}
          className={styles.inputSearch}
          name="search"
          type={type}
          {...props}
        />

        <Button
          type="submit"
          variant="default"
          size="small"
          label={buttonLabel}
        />
      </label>
    </form>
  );
};

import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '../styles.module.css';

export interface AccordionSubtitleProps
  extends PropsWithChildren<HTMLAttributes<HTMLElement>> {}

export const AccordionSubtitle: React.FC<AccordionSubtitleProps> = ({
  children,
  ...props
}) => {
  return (
    <div className={styles.accordionSubtitle} {...props}>
      {children}
    </div>
  );
};

import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '../styles.module.css';

export interface DropdownContentProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  active?: boolean;
}

export const DropdownContent: React.FC<DropdownContentProps> = ({
  children,
  active = false,
  ...props
}) => {
  return (
    <main>
      <section
        className={`${styles.dropdownContent} ${
          active ? styles.dropdownContentActive : ''
        }`}
        {...props}
      >
        {children}
      </section>
    </main>
  );
};

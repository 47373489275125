import React from 'react';
import style from './styles.module.css';

interface BreadcrumbItem {
  name: string;
  path?: string;
}

export interface BreadcrumbsProps extends React.HTMLAttributes<HTMLElement> {
  items: BreadcrumbItem[];
  className?: string;
  underline?: 'hover' | 'always' | 'none';
  separator?: string | React.ReactNode;
}

export const Breadcrumbs = ({
  items,
  className = '',
  underline = 'hover',
  separator = '/',
  ...props
}: BreadcrumbsProps) => {
  return (
    <nav
      className={`${style.breadcrumbsContainer} ${className}`}
      aria-label="breadcrumb"
      {...props}
    >
      {items.map(({ name, path }, index) => {
        const isLastItem = index === items.length - 1;

        return (
          <span
            key={name}
            className={isLastItem ? style.breadcrumbLast : style.breadcrumbItem}
          >
            {path && !isLastItem ? (
              <a
                href={path}
                className={`${style.breadcrumbLink} ${style[`${underline}`]}`}
              >
                {name}
              </a>
            ) : (
              <span>{name}</span>
            )}
            {!isLastItem && (
              <span className={style.separator}>{separator}</span>
            )}
          </span>
        );
      })}
    </nav>
  );
};

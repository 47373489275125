import React from 'react';
import styles from './styles.module.css';

export interface TitleProps {
  title: string;
  info?: string;
  markerColor?: 'primary' | 'grey40';
  iconInfo?: React.ReactNode;
  description?: string;
  size?: 'large' | 'medium';
  color?: 'grey90' | 'grey10';
}

export const Title = ({
  title,
  description,
  info,
  iconInfo,
  size = 'medium',
  color = 'grey90',
  markerColor = 'primary',
}: TitleProps) => {
  return (
    <div className={`${styles.titleContainer}`}>
      <div className={styles.titleContent}>
        <span className={`${styles.infoMarker} ${styles[markerColor]}`} />
        <div>
          {info && (
            <div className={styles.infoContainer}>
              {iconInfo}
              <span className={styles.text}>{info}</span>
            </div>
          )}
          <h1
            className={`${styles.titleText} ${styles[size]} ${styles[color]}`}
          >
            {title}
          </h1>
          {description && <p className={`${styles.text}`}>{description}</p>}
        </div>
      </div>
    </div>
  );
};

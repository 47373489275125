import React, { ChangeEvent, InputHTMLAttributes } from 'react';

import styles from './styles.module.css';

export interface SwitchProps extends InputHTMLAttributes<HTMLInputElement> {
  setChecked: (checked: boolean) => void;
}

export const Switch: React.FC<SwitchProps> = ({
  id,
  checked = false,
  setChecked,
  onChange,
  ...props
}) => {
  function handleChange(e: ChangeEvent<HTMLInputElement>) {
    setChecked(e.target.checked);
    onChange?.(e);
  }

  return (
    <label htmlFor={id} className={styles.switch}>
      <input
        id={id}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        {...props}
      />
    </label>
  );
};

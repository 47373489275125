import React, { HTMLAttributes, PropsWithChildren } from 'react';

import styles from '../styles.module.css';

export interface AccordionContentProps
  extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
  active?: boolean;
  divider?: boolean;
}

export const AccordionContent: React.FC<AccordionContentProps> = ({
  children,
  active = false,
  divider = true,
  ...props
}) => {
  return (
    <main
      className={`${styles.accordionContent} ${active ? styles.active : ''}`}
      {...props}
    >
      {divider && <hr />}

      {children}
    </main>
  );
};
